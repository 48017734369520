import { Scroller } from '@/components/atoms/InfiniteScrollV2'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ActionMenu } from '@/components/molecules/interactive/ActionMenu'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { useChat } from '@/components/providers/site/ChatProvider'
import { useTime } from '@/core/hooks/useTime'
import { useChatMessages } from '@/entity/chat/useChatMessages'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { calcElevationString } from '@/style/palette'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Portal from '@mui/material/Portal'
import Stack from '@mui/material/Stack'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { ChatAccessLevel } from '@rallycry/social-api-typescript'
import { last, orderBy, some } from 'lodash-es'
import moment from 'moment-timezone'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ChatInput } from './ChatInput'
import { CommunityTicketHeader, CompetitionTicketHeader } from './ChatInstance'
import { ChatMessageRow } from './ChatMessageRow'
import { useChatRoomActions } from './useChatRoomActions'

export interface ChatRoomProps {
  chatId?: string
  onNavigate?: () => void
  onLeave?: () => void
  allowExpand?: boolean
  allowDocking?: boolean
  autoFocus?: boolean
}

const adminLevels: ChatAccessLevel[] = [
  ChatAccessLevel.MODERATOR,
  ChatAccessLevel.OWNER
]

export const ChatRoom = (props: ChatRoomProps) => {
  const [shouldScroll, setShouldScroll] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [lastRead, setLastRead] = useState<Date>(moment().toDate())
  const [isEditing, setIsEditing] = useState('')

  const contentRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const lastScrollHeight = useRef(0)

  const { account } = useUserAccount()
  const { displayTime, getNow } = useTime()

  const { topActionContainer, titleContainer } = useModal()

  const { updateChatActivity } = useChat()

  const { chatId } = props
  const chatRoomDocument = useChatRoomDocument({
    idOrKey: chatId,
    shouldSubscribe: true
  })

  const {
    room,
    members,
    attributes: { childId, childType }
  } = chatRoomDocument

  const isChatAdmin = members?.some(
    it => Number(it.id) === account?.id && adminLevels.includes(it.accessLevel!)
  )

  // options for the action menu
  const options = useChatRoomActions(props, chatRoomDocument)

  const {
    messages: conversation,
    updateMessage,
    deleteMessage,
    loadMore,
    postMessage
  } = useChatMessages({
    idOrKey: chatId,
    shouldSubscribe: true,
    includeDeleted: isChatAdmin
  })

  // keep track of last time we saw the bottom
  useLayoutEffect(() => {
    if (!shouldScroll) {
      setLastRead(moment().toDate())
    }
  }, [shouldScroll])

  // track if the user scrolls to/away from the bottom
  useLayoutEffect(() => {
    const handle = () => {
      const scrolledUp =
        contentRef?.current?.scrollTop! < lastScrollHeight.current
      lastScrollHeight.current = contentRef?.current?.scrollTop!

      if (scrolledUp) {
        setShouldScroll(false)
        return
      }

      const scrolledToBottom =
        contentRef?.current?.scrollHeight! -
          contentRef?.current?.scrollTop! -
          contentRef?.current?.clientHeight! <
        10

      if (scrolledToBottom) setShouldScroll(true)
    }

    const curr = contentRef?.current
    curr?.addEventListener('scroll', handle, false)
    return () => {
      curr?.removeEventListener('scroll', handle, false)
    }
  }, [])

  // handle animation resizing of div
  useLayoutEffect(() => {
    const ref = contentRef?.current
    const observer = new ResizeObserver(() => {
      setShouldScroll(true)
    })
    if (!ref) return

    observer.observe(ref)
    return () => {
      observer.unobserve(ref!)
    }
  }, [])

  // keep the view stuck to the bottom when new messages arrive
  useEffect(() => {
    const tick = () => {
      if (shouldScroll) {
        contentRef?.current?.scrollTo?.({
          top: contentRef?.current?.scrollHeight! + 1,
          behavior: 'auto'
        })
      }
    }
    const timer = setInterval(tick, 50)
    tick()
    return () => clearInterval(timer)
  }, [shouldScroll, conversation])

  const display = useMemo(() => {
    const sorted = orderBy(conversation, 'created', 'asc')
    return sorted.map((it, idx) => {
      const prev = sorted[idx - 1]
      const isNewDay =
        prev && !moment(prev.dateCreated).isSame(it.dateCreated, 'day')
      const isNewUserSpeech = Number(prev?.author) !== Number(it.author)
      const isGap =
        prev &&
        moment(prev.dateCreated).add(5, 'minutes').isBefore(it.dateCreated)

      return (
        <ChatMessageRow
          key={it.uid}
          chatId={chatId!}
          chatMessage={it}
          members={members}
          isChatAdmin={isChatAdmin}
          isOwnChat={Number(it.author) === account?.id}
          isNewDay={isNewDay}
          isNewUserSpeech={isNewUserSpeech || isNewDay || isGap}
          onUpdate={value => {
            updateMessage(it, value)
            setTimeout(() => inputRef.current?.focus())
          }}
          onDelete={() => deleteMessage(it.uid!)}
          isEditing={isEditing === it.uid}
          onEdit={editing => {
            setIsEditing(editing ? it.uid : '')
            inputRef.current?.scrollIntoView({ behavior: 'auto' })
            inputRef.current?.focus()
          }}
        />
      )
    })
  }, [
    chatId,
    conversation,
    updateMessage,
    deleteMessage,
    isEditing,
    members,
    isChatAdmin,
    account?.id
  ])

  const handleLoadMore = async () => {
    setHasMore(false)
    setIsLoading(true)
    const res = await loadMore()

    // try to keep user from sticking to the top and loading all...
    if (contentRef?.current?.scrollTop! < 100 && res) {
      contentRef?.current?.scrollTo?.({
        top: 351,
        behavior: 'auto'
      })
    }

    setIsLoading(false)
    setHasMore(res)
  }

  const handleScrollToMessage = () => {
    const target = conversation.find(it =>
      moment(it.dateCreated).isAfter(lastRead)
    )
    const anchor = document.querySelector(`#msg-${target?.uid}`)
    anchor?.scrollIntoView({ behavior: 'smooth' })
    setShouldScroll(true)
  }

  const handleChatSubmit = async (message: string) => {
    postMessage(message)
    setShouldScroll(true)
  }

  const handleMarkAsRead = () => {
    setLastRead(moment().toDate())
  }

  const handleEditPrevious = () => {
    const previousChat = orderBy(conversation, 'dateCreated', 'desc').find(
      it => it.author === account?.id
    )
    if (previousChat) {
      setShouldScroll(true)
      setIsEditing(previousChat.uid)
    }
  }

  const unreadCount = conversation?.filter(it =>
    moment(it.dateCreated).isAfter(lastRead)
  ).length

  // keep track of last time user read recent chat
  const lastMessage = last(conversation)
  useEffect(() => {
    if (!shouldScroll) return
    return updateChatActivity(chatId!, { lastActive: getNow()?.toDate() })
  }, [shouldScroll, lastMessage?.uid, getNow, chatId, updateChatActivity])

  // clear out old message when leaving view
  // useEffect(() => {
  //   return () => prune()
  // }, [prune])

  return (
    <Stack
      direction='column'
      width='100%'
      flexGrow={1}
      spacing={1}
      sx={theme => ({
        backgroundColor: theme.palette.primary.main,
        backgroundImage: calcElevationString(theme.palette.mode, [1])
      })}
    >
      <Portal container={titleContainer?.current}>{room?.name}</Portal>
      <Portal container={topActionContainer?.current}>
        {some(options) ? (
          <ActionMenu options={options} buttonProps={{ size: 'small' }} />
        ) : null}
      </Portal>
      {childType === EntityType.COMMUNITY_TICKET ? (
        <CommunityTicketHeader
          key={room?.archived ? 'active' : 'archived'}
          ticketId={childId}
        />
      ) : childType === EntityType.COMPETITION_TICKET ? (
        <CompetitionTicketHeader
          key={room?.archived ? 'active' : 'archived'}
          ticketId={childId}
        />
      ) : null}
      <Box
        sx={{
          position: 'relative',
          flexGrow: 1,
          height: '100%'
        }}
      >
        {!shouldScroll && unreadCount ? (
          <Fade in style={{ position: 'sticky', top: 0 }}>
            <Alert
              sx={theme => ({
                width: `100%`,
                borderRadius: { xs: 0, md: 0 },
                zIndex: theme.zIndex.tooltip
              })}
              action={
                <RcButton variant='text' onClick={handleMarkAsRead}>
                  <RcTrans i18nKey='shared.mark-as-read' />
                </RcButton>
              }
            >
              <RcButton variant='text' onClick={handleScrollToMessage}>
                <RcTrans
                  i18nKey='shared.new-messages'
                  tOptions={{
                    count: unreadCount,
                    since: displayTime(lastRead)
                  }}
                />
              </RcButton>
            </Alert>
          </Fade>
        ) : null}

        <Stack direction='row' height='100%'>
          <Box
            position='absolute'
            bottom={0}
            left={0}
            maxHeight='100%'
            width={'100%'}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            ref={contentRef}
          >
            {!isLoading && hasMore ? (
              <Scroller
                onLoad={handleLoadMore}
                rootMargin={'550px'}
                root={contentRef}
              />
            ) : null}
            <RcButton
              onClick={handleLoadMore}
              disabled={isLoading || !hasMore}
              size='small'
              fullWidth
              variant='text'
            >
              {isLoading ? (
                <RcTrans i18nKey='shared.loading' />
              ) : hasMore ? (
                <RcTrans i18nKey='shared.load-more' />
              ) : (
                <RcTrans i18nKey='shared.all-caught-up' />
              )}
            </RcButton>

            <Stack direction='column'>
              {display}

              <div ref={scrollRef} style={{ userSelect: 'none' }}>
                &nbsp;
              </div>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box>
        <ChatInput
          archived={room?.archived}
          chatId={chatId!}
          inputRef={inputRef}
          onSubmit={val => handleChatSubmit(val)}
          onEditPrevious={() => handleEditPrevious()}
          autoFocus={props.autoFocus}
        />
      </Box>
    </Stack>
  )
}

// t('shared.new-messages', { count })
// t('shared.new-messages', { count })
