import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { lighten, useTheme } from '@mui/material/styles'
import Color from 'color'
import { Form, Formik, FormikHelpers } from 'formik'
import { RefObject, useEffect, useRef } from 'react'
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions'
import { RenderReaction } from './ChatMessageText'
import { ReactionPicker } from './ReactionPicker'
import { REACTIONS } from './REACTIONS'
import { TenorPicker } from './TenorSearch'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { calcElevationString } from '@/style/palette'

const MAX_CHAT_HEIGHT = 100
export const ChatInput = ({
  archived,
  chatId,
  autoFocus,
  inputRef: externalInputRef,
  existing,
  onSubmit,
  onEditPrevious,
  onCancel
}: {
  archived?: boolean
  chatId: string
  autoFocus?: boolean
  inputRef?: RefObject<HTMLInputElement>
  existing?: string
  onSubmit: (message: string) => any
  onEditPrevious?: () => any
  onCancel?: () => any
}) => {
  const isMobile = useIsMobile()
  const { featChatGifs } = useFeatures()
  const { t } = useRcTranslation()
  const theme = useTheme()
  const { members } = useChatRoomDocument({ idOrKey: chatId })
  const internalInputRef = useRef<HTMLInputElement>(null)
  const initial = { message: existing || '' }
  const inputRef = externalInputRef || internalInputRef

  const handleSubmit = async (
    values: typeof initial,
    helpers: FormikHelpers<typeof initial>
  ) => {
    helpers.resetForm()
    if (!values?.message?.trim()) return
    onSubmit(values.message.trim())
    inputRef.current?.focus?.()
  }

  useEffect(() => {
    autoFocus && inputRef.current?.focus()
  }, [autoFocus, inputRef])

  useEffect(() => {
    if (existing) {
      inputRef.current?.focus()
      const length = inputRef.current?.value.length || 0
      setTimeout(() => inputRef.current?.setSelectionRange(length, length))
    }
  }, [existing, inputRef])

  const uniqueAts = [
    // {
    //   id: -2,
    //   display: 'all',
    //   subtitle: t('shared.notify-all')
    // },
    // {
    //   id: -1,
    //   display: 'here',
    //   subtitle: t('shared.notify-here')
    // },
    ...members?.map(it => ({
      id: it.id!,
      display: it.name,
      subtitle: it.image
    }))
  ]

  if (archived) {
    return (
      <Typography
        variant='subtitle2'
        color='text.secondary'
        textAlign='center'
        pb={5}
      >
        <RcTrans i18nKey='shared.chat-archived' />
      </Typography>
    )
  }

  return (
    <Formik initialValues={initial} enableReinitialize onSubmit={handleSubmit}>
      {({ values, setFieldValue, submitForm }) => (
        <Form id='chat-input-form'>
          <Box
            sx={theme => ({
              position: 'relative',
              '& .mentions__control ': {
                padding: theme.spacing(1),
                maxHeight: MAX_CHAT_HEIGHT,
                backgroundColor: theme.palette.background.paper,
                backgroundImage: calcElevationString(theme.palette.mode, [4]),
                overflow: 'hidden'
              },
              '& .mentions__highlighter ': {
                padding: theme.spacing(1),
                paddingRight: { xs: '80px', md: '30px' },
                maxHeight: MAX_CHAT_HEIGHT
              },
              '& .mentions__input': {
                color: theme.palette.text.primary,
                padding: theme.spacing(2),
                paddingRight: { xs: '125px', md: '80px' },
                maxHeight: MAX_CHAT_HEIGHT,
                borderColor: theme.palette.divider,
                borderWidth: 1
              },
              '& .mentions__input:focus': {
                outline: 'none !important',
                borderColor: theme.palette.primary.main
              },
              '.mentions__suggestions': {
                backgroundColor: `${lighten(
                  theme?.palette.background.paper,
                  0.1
                )} !important`,
                paddingY: theme.spacing(2),
                borderRadius: 1,
                maxHeight: 300,
                overflowY: 'auto'
              },
              '.mentions__suggestions__item': {
                minWidth: 250,
                paddingY: theme.spacing(2),
                paddingX: theme.spacing(4),
                backgroundColor: lighten(theme?.palette.background.paper, 0.1)
              },
              '.mentions__suggestions__item--focused': {
                backgroundColor: lighten(theme?.palette.background.paper, 0.2)
              }
            })}
          >
            <Box>
              <MentionsInput
                className='mentions'
                inputRef={inputRef}
                value={values.message}
                onChange={evt => {
                  const val = evt.target.value
                  setFieldValue('message', val.trimStart())
                }}
                onKeyDown={evt => {
                  if (evt.key === 'Enter' && !evt.shiftKey) submitForm()
                  if (evt.key === 'Escape') onCancel?.()
                  if (evt.key === 'ArrowUp' && !values.message) {
                    onEditPrevious?.()
                  }
                }}
                placeholder={
                  isMobile ? undefined : t('shared.chat-placeholder')
                }
                allowSuggestionsAboveCursor
                forceSuggestionsAboveCursor
              >
                <Mention
                  trigger='@'
                  data={uniqueAts}
                  markup={`[@__display__](__id__)`}
                  displayTransform={(val, display) => `@${display}`}
                  renderSuggestion={(
                    val: SuggestionDataItem & { subtitle?: string }
                  ) =>
                    Number(val.id) > 1 ? (
                      <AvatarText src={val.subtitle} size='small'>
                        <Typography>{val.display}</Typography>
                      </AvatarText>
                    ) : (
                      <Stack
                        direction='row'
                        spacing={1}
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Typography variant='body0'>@{val.display}</Typography>
                        <Typography variant='subtitle2' color='text.secondary'>
                          {val.subtitle}
                        </Typography>
                      </Stack>
                    )
                  }
                  style={{
                    backgroundColor: Color(theme.palette.primary.main)
                      .fade(0.75)
                      .rgb()
                      .string()
                  }}
                />
                <Mention
                  trigger=':'
                  data={REACTIONS}
                  markup={`[:__id__:](__display__)`}
                  displayTransform={val => `:${val}:`}
                  renderSuggestion={(val: any) => (
                    <Stack direction='row' spacing={3} alignItems='center'>
                      <RenderReaction id={val.id} />{' '}
                      <Typography variant='subtitle2'>:{val.id}:</Typography>
                    </Stack>
                  )}
                  style={{
                    backgroundColor: Color(theme.palette.primary.main)
                      .fade(0.75)
                      .rgb()
                      .string()
                  }}
                />
              </MentionsInput>
            </Box>

            <Box sx={{ position: 'absolute', right: 2, top: 2 }}>
              <Stack direction='row' alignItems='center'>
                {featChatGifs ? (
                  <>
                    <TenorPicker
                      onSelected={val => {
                        onSubmit(val)
                      }}
                    />
                    <ReactionPicker
                      onSelected={value => {
                        setFieldValue(
                          'message',
                          [values.message, `[:${value}:]( )`].join('')
                        )
                      }}
                    />
                  </>
                ) : null}

                {isMobile ? (
                  <RcIconButton
                    disabled={!values?.message}
                    disableRipple
                    icon={['fad', 'paper-plane']}
                    onClick={submitForm}
                    sx={theme => ({
                      color: theme.palette.primary.main,
                      ['&:hover']: { color: theme.palette.text.primary }
                    })}
                  />
                ) : null}
              </Stack>
            </Box>
            {existing ? (
              <Typography variant='subtitle2' color='text.secondary'>
                <RcTrans i18nKey='shared.chat-cancel-save'>
                  escape to
                  <a onClick={onCancel} href='#'>
                    cancel
                  </a>
                  • enter to
                  <a onClick={submitForm} href='#'>
                    save
                  </a>
                </RcTrans>
              </Typography>
            ) : null}
          </Box>
        </Form>
      )}
    </Formik>
  )
}
