import { RcTrans } from '@/components/atoms/RcTrans'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { ChatMessage } from '@/entity/chat/useChatMessages'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useConfig } from '@/entity/site/useConfig'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

export const ChatMessageDeleted = ({
  chatMessage,
  chatId
}: {
  chatMessage: ChatMessage
  chatId: string
}) => {
  const { isOrgSuperAdmin } = useOrganization()
  const { projectId } = useConfig()
  const revisionsLink = `https://console.firebase.google.com/u/0/project/${projectId}/firestore/databases/-default-/data/~2FSocial_Chat~2F${chatId}~2Fmessages~2F${chatMessage.uid}`
  const tooltipTitle = isOrgSuperAdmin ? (
    <RcTrans i18nKey='shared.chat-message-deleted-revisions-admin'>
      <NavigationLink target='_blank' href={revisionsLink} />
    </RcTrans>
  ) : (
    <RcTrans i18nKey='shared.chat-message-deleted-revisions'>
      Send RC this revisions link
      <NavigationLink target='_blank' href={revisionsLink} />
    </RcTrans>
  )

  return (
    <Tooltip placement='top-start' title={tooltipTitle}>
      <Typography variant='body2' color='info'>
        <RcTrans i18nKey='shared.chat-message-deleted' />
      </Typography>
    </Tooltip>
  )
}
